/**
 * Automatically Generated with Rails
 * see {@link lib_development/generators/user_roles/user_roles_generator.rb} for details
 * run `rails g user_roles -f` to regenerate
 */

import * as React from 'react';

/**
 * All the possible roles that can be used within the application
 */
export type UserRolesKeys =
  | 'company_manager'
  | 'template_manager'
  | 'user_manager'
  | 'agency_manager'
  | 'agency_application_manager'
  | 'permissions_manager'
  | 'candidate_manager'
  | 'candidate_viewer'
  | 'careers_site_manager'
  | 'talent_pipeline_manager'
  | 'public_pool_creator'
  | 'company_teams_manager'
  | 'calendar_manager'
  | 'requisition_manager'
  | 'requisition_creator'
  | 'requisition_viewer'
  | 'requisition_editor'
  | 'requisition_job_creator'
  | 'draft_job_creator'
  | 'private_job_creator'
  | 'job_publisher'
  | 'job_manager'
  | 'stage_mover'
  | 'archive_manager'
  | 'job_remover'
  | 'application_exporter'
  | 'marketing_manager'
  | 'application_creator'
  | 'application_editor'
  | 'interview_attendee'
  | 'interview_manager'
  | 'interview_viewer'
  | 'group_assessment_manager'
  | 'group_assessment_creator'
  | 'sensitive_answer_viewer'
  | 'background_check_viewer'
  | 'background_check_creator'
  | 'candidate_assessment_viewer'
  | 'candidate_assessment_creator'
  | 'offer_manager'
  | 'offer_creator'
  | 'offer_sender'
  | 'offer_viewer'
  | 'offer_editor'
  | 'comment_viewer'
  | 'comment_manager'
  | 'email_and_sms_manager'
  | 'email_and_sms_viewer'
  | 'document_manager'
  | 'document_viewer'
  | 'folder_manager'
  | 'history_viewer'
  | 'onboarding_manager'
  | 'onboarding_admin'
  | 'onboarding_operator'
  | 'reporting_manager'
  | 'insight_manager'
  | 'referral_manager'
  | 'workflow_automations_manager';

/**
 * The shape of our UserRoles object, narrowed down to the available keys and values only.
 */
export interface IUserRoles {
  company_manager: boolean;
  template_manager: boolean;
  user_manager: boolean;
  agency_manager: boolean;
  agency_application_manager: boolean;
  permissions_manager: boolean;
  candidate_manager: boolean;
  candidate_viewer: boolean;
  careers_site_manager: boolean;
  talent_pipeline_manager: boolean;
  public_pool_creator: boolean;
  company_teams_manager: boolean;
  calendar_manager: boolean;
  requisition_manager: boolean;
  requisition_creator: boolean;
  requisition_viewer: boolean;
  requisition_editor: boolean;
  requisition_job_creator: boolean;
  draft_job_creator: boolean;
  private_job_creator: boolean;
  job_publisher: boolean;
  job_manager: boolean;
  stage_mover: boolean;
  archive_manager: boolean;
  job_remover: boolean;
  application_exporter: boolean;
  marketing_manager: boolean;
  application_creator: boolean;
  application_editor: boolean;
  interview_attendee: boolean;
  interview_manager: boolean;
  interview_viewer: boolean;
  group_assessment_manager: boolean;
  group_assessment_creator: boolean;
  sensitive_answer_viewer: boolean;
  background_check_viewer: boolean;
  background_check_creator: boolean;
  candidate_assessment_viewer: boolean;
  candidate_assessment_creator: boolean;
  offer_manager: boolean;
  offer_creator: boolean;
  offer_sender: boolean;
  offer_viewer: boolean;
  offer_editor: boolean;
  comment_viewer: boolean;
  comment_manager: boolean;
  email_and_sms_manager: boolean;
  email_and_sms_viewer: boolean;
  document_manager: boolean;
  document_viewer: boolean;
  folder_manager: boolean;
  history_viewer: boolean;
  onboarding_manager: boolean;
  onboarding_admin: boolean;
  onboarding_operator: boolean;
  reporting_manager: boolean;
  insight_manager: boolean;
  referral_manager: boolean;
  workflow_automations_manager: boolean;
}

/**
 * Initial value for all user roles.
 *
 */
export const userRolesInitialValues: IUserRoles = {
company_manager: false,
template_manager: false,
user_manager: false,
agency_manager: false,
agency_application_manager: false,
permissions_manager: false,
candidate_manager: false,
candidate_viewer: false,
careers_site_manager: false,
talent_pipeline_manager: false,
public_pool_creator: false,
company_teams_manager: false,
calendar_manager: false,
requisition_manager: false,
requisition_creator: false,
requisition_viewer: false,
requisition_editor: false,
requisition_job_creator: false,
draft_job_creator: false,
private_job_creator: false,
job_publisher: false,
job_manager: false,
stage_mover: false,
archive_manager: false,
job_remover: false,
application_exporter: false,
marketing_manager: false,
application_creator: false,
application_editor: false,
interview_attendee: false,
interview_manager: false,
interview_viewer: false,
group_assessment_manager: false,
group_assessment_creator: false,
sensitive_answer_viewer: false,
background_check_viewer: false,
background_check_creator: false,
candidate_assessment_viewer: false,
candidate_assessment_creator: false,
offer_manager: false,
offer_creator: false,
offer_sender: false,
offer_viewer: false,
offer_editor: false,
comment_viewer: false,
comment_manager: false,
email_and_sms_manager: false,
email_and_sms_viewer: false,
document_manager: false,
document_viewer: false,
folder_manager: false,
history_viewer: false,
onboarding_manager: false,
onboarding_admin: false,
onboarding_operator: false,
reporting_manager: false,
insight_manager: false,
referral_manager: false,
workflow_automations_manager: false,
}

const UserRolesContext = React.createContext<IUserRoles>(userRolesInitialValues);

export const useUserRoles = () => {
  const userRoles = React.useContext(UserRolesContext);

  return React.useMemo(
    () => ({
            isCompanyManager: userRoles.company_manager,
            isTemplateManager: userRoles.template_manager,
            isUserManager: userRoles.user_manager,
            isAgencyManager: userRoles.agency_manager,
            isAgencyApplicationManager: userRoles.agency_application_manager,
            isPermissionsManager: userRoles.permissions_manager,
            isCandidateManager: userRoles.candidate_manager,
            isCandidateViewer: userRoles.candidate_viewer,
            isCareersSiteManager: userRoles.careers_site_manager,
            isTalentPipelineManager: userRoles.talent_pipeline_manager,
            isPublicPoolCreator: userRoles.public_pool_creator,
            isCompanyTeamsManager: userRoles.company_teams_manager,
            isCalendarManager: userRoles.calendar_manager,
            isRequisitionManager: userRoles.requisition_manager,
            isRequisitionCreator: userRoles.requisition_creator,
            isRequisitionViewer: userRoles.requisition_viewer,
            isRequisitionEditor: userRoles.requisition_editor,
            isRequisitionJobCreator: userRoles.requisition_job_creator,
            isDraftJobCreator: userRoles.draft_job_creator,
            isPrivateJobCreator: userRoles.private_job_creator,
            isJobPublisher: userRoles.job_publisher,
            isJobManager: userRoles.job_manager,
            isStageMover: userRoles.stage_mover,
            isArchiveManager: userRoles.archive_manager,
            isJobRemover: userRoles.job_remover,
            isApplicationExporter: userRoles.application_exporter,
            isMarketingManager: userRoles.marketing_manager,
            isApplicationCreator: userRoles.application_creator,
            isApplicationEditor: userRoles.application_editor,
            isInterviewAttendee: userRoles.interview_attendee,
            isInterviewManager: userRoles.interview_manager,
            isInterviewViewer: userRoles.interview_viewer,
            isGroupAssessmentManager: userRoles.group_assessment_manager,
            isGroupAssessmentCreator: userRoles.group_assessment_creator,
            isSensitiveAnswerViewer: userRoles.sensitive_answer_viewer,
            isBackgroundCheckViewer: userRoles.background_check_viewer,
            isBackgroundCheckCreator: userRoles.background_check_creator,
            isCandidateAssessmentViewer: userRoles.candidate_assessment_viewer,
            isCandidateAssessmentCreator: userRoles.candidate_assessment_creator,
            isOfferManager: userRoles.offer_manager,
            isOfferCreator: userRoles.offer_creator,
            isOfferSender: userRoles.offer_sender,
            isOfferViewer: userRoles.offer_viewer,
            isOfferEditor: userRoles.offer_editor,
            isCommentViewer: userRoles.comment_viewer,
            isCommentManager: userRoles.comment_manager,
            isEmailAndSmsManager: userRoles.email_and_sms_manager,
            isEmailAndSmsViewer: userRoles.email_and_sms_viewer,
            isDocumentManager: userRoles.document_manager,
            isDocumentViewer: userRoles.document_viewer,
            isFolderManager: userRoles.folder_manager,
            isHistoryViewer: userRoles.history_viewer,
            isOnboardingManager: userRoles.onboarding_manager,
            isOnboardingAdmin: userRoles.onboarding_admin,
            isOnboardingOperator: userRoles.onboarding_operator,
            isReportingManager: userRoles.reporting_manager,
            isInsightManager: userRoles.insight_manager,
            isReferralManager: userRoles.referral_manager,
            isWorkflowAutomationsManager: userRoles.workflow_automations_manager,
          }),
    [userRoles],
  );
};

interface IUserRolesProviderProps {
  userRoles: IUserRoles;
}

export function UserRolesProvider({
    userRoles,
    children,
  }: React.PropsWithChildren<IUserRolesProviderProps>) {
  return (
    <UserRolesContext.Provider
      value={{
        ...userRolesInitialValues,
        ...userRoles,
      }}
    >
      {children}
    </UserRolesContext.Provider>
  );
}
